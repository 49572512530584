<script>
import Banner from 'dashboard/components/ui/Banner.vue';

export default {
  components: { Banner },
  props: {
    latestChatwootVersion: { type: String, default: '' },
  },
  setup() {
    return {
      isAdmin: false, // Ajustamos esta propiedad si fuera relevante para otros cálculos
    };
  },
  data() {
    return { userDismissedBanner: false };
  },
  computed: {
    bannerMessage() {
      return false; // No se genera contenido para el banner
    },
    shouldShowBanner() {
      return false; // Siempre devolvemos falso para que el banner nunca se muestre
    },
  },
  methods: {
    dismissUpdateBanner() {
      // Este método es redundante si nunca mostramos el banner, pero lo dejamos para evitar conflictos
      this.userDismissedBanner = true;
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <!-- No se renderiza nada porque shouldShowBanner siempre es false -->
  <Banner
    v-if="shouldShowBanner"
    color-scheme="primary"
    :banner-message="bannerMessage"
    href-link="https://github.com/chatwoot/chatwoot/releases"
    :href-link-text="$t('GENERAL_SETTINGS.LEARN_MORE')"
    has-close-button
    @close="dismissUpdateBanner"
  />
</template>

